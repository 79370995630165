import { Controller } from "@hotwired/stimulus"
import { disable, enable, hide, show } from "../utils"
import { debounce } from "lodash"
import { pluralize } from "../utils/inflector"

// Connects to data-controller="bulk-edit"
export default class extends Controller {
  bulkSelectTargets: HTMLSelectElement[]
  selectTargets: HTMLSelectElement[]
  submitBtnTarget: HTMLButtonElement
  errorsSidebarCollapsedTarget: HTMLDivElement
  errorsSidebarExpandedTarget: HTMLDivElement
  bulkEditSectionTarget: HTMLDivElement
  errorsPillTarget: HTMLButtonElement

  hasErrorsSidebarExpandedTarget: boolean
  hasErrorsSidebarCollapsedTarget: boolean

  static targets = [
    "bulkSelect",
    "select",
    "submitBtn",
    "errorsSidebarCollapsed",
    "errorsSidebarExpanded",
    "bulkEditSection",
    "errorsPill",
  ]

  // Semaphore to prevent infinite loop due to tomselect triggering change event on setValue
  propagateEvents = true

  onInitializeFunction: (event) => void
  onHighlighColumnFunction: (event) => void
  onRemoveHighlightColumnFunction: (event) => void
  onSelectedUpdatedFunction: (event) => void
  onErrorsSidebarCollapsedFunction: (event) => void
  onErrorsSidebarExpandedFunction: (event) => void
  onFieldEnteredFunction: (event) => void

  connect(): void {
    this.onInitializeFunction = this.onInitialize.bind(this)
    this.onHighlighColumnFunction = this.onHighlighColumn.bind(this)
    this.onRemoveHighlightColumnFunction = this.onRemoveHighlightColumn.bind(this)
    this.onSelectedUpdatedFunction = this.onSelectedUpdated.bind(this)
    this.onErrorsSidebarCollapsedFunction = this.onErrorsSidebarCollapsed.bind(this)
    this.onErrorsSidebarExpandedFunction = this.onErrorsSidebarExpanded.bind(this)
    this.onFieldEnteredFunction = debounce(this.onFieldEntered.bind(this), 200)

    window.addEventListener("StyledSelect:initialize", this.onInitializeFunction)
    window.addEventListener("StyledSelect:dropdownOpened", this.onHighlighColumnFunction)
    window.addEventListener("StyledSelect:dropdownClosed", this.onRemoveHighlightColumnFunction)
    window.addEventListener("CompactMultiSelect:popperOpened", this.onHighlighColumnFunction)
    window.addEventListener("CompactMultiSelect:popperClosed", this.onRemoveHighlightColumnFunction)
    window.addEventListener("UndoRedo:selectUpdated", this.onSelectedUpdatedFunction)
    window.addEventListener("collapsible-sidebar:collapsed", this.onErrorsSidebarCollapsedFunction)
    window.addEventListener("collapsible-sidebar:expanded", this.onErrorsSidebarExpandedFunction)
    window.addEventListener("change", this.onFieldEnteredFunction)

    this.clearBulkSelection()

    window.addEventListener("bulkEdit:rowRerendering", () => disable(this.submitBtnTarget))
    window.addEventListener("bulkEdit:rowRerendered", () => enable(this.submitBtnTarget))
  }

  disconnect(): void {
    window.removeEventListener("StyledSelect:initialize", this.onInitializeFunction)
    window.removeEventListener("StyledSelect:dropdownOpened", this.onHighlighColumnFunction)
    window.removeEventListener("StyledSelect:dropdownClosed", this.onRemoveHighlightColumnFunction)
    window.removeEventListener("CompactMultiSelect:popperOpened", this.onHighlighColumnFunction)
    window.removeEventListener("CompactMultiSelect:popperClosed", this.onRemoveHighlightColumnFunction)
    window.removeEventListener("UndoRedo:selectUpdated", this.onSelectedUpdatedFunction)
    window.removeEventListener("collapsible-sidebar:collapsed", this.onErrorsSidebarCollapsedFunction)
    window.removeEventListener("bulkEdit:rowRerendering", () => disable(this.submitBtnTarget))
    window.removeEventListener("bulkEdit:rowRerendered", () => enable(this.submitBtnTarget))
  }

  private onInitialize(event): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      const select = event.detail.origin

      if (this.bulkSelectTargets.includes(select)) {
        this.refreshBulkSelect(select)
      }

      this.propagateEvents = true
    }
  }

  private onHighlighColumn(event): void {
    const { origin, _container } = event.detail

    if (origin.dataset.highlightColumn) {
      const columnName = this.getColumnName(origin)

      this.getColumnCells(columnName).forEach((element) => element.classList.add("highlighted"))
    }
  }

  private onSelectedUpdated(event): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      const { origin } = event.detail

      if (origin) {
        const columnName = this.getColumnName(origin)
        const bulkSelect = this.getBulkSelect(columnName)

        this.refreshBulkSelect(bulkSelect)
      }

      this.propagateEvents = true
    }
  }

  private onErrorsSidebarCollapsed(event): void {
    if (this.hasErrorsSidebarExpandedTarget) {
      show(this.errorsSidebarCollapsedTarget)
      hide(this.errorsSidebarExpandedTarget)
      this.bulkEditSectionTarget.style.width = "calc(100% - 85px)"
    }
  }

  private onErrorsSidebarExpanded(event): void {
    if (this.hasErrorsSidebarCollapsedTarget) {
      hide(this.errorsSidebarCollapsedTarget)
      show(this.errorsSidebarExpandedTarget)
      this.bulkEditSectionTarget.style.width = "calc(100% - 410px)"
    }
  }

  private onFieldEntered(event): void {
    let errorDivId = event.target.id.concat("_error")
    let errorCards = document.querySelectorAll(`#${errorDivId}`)
    let totalToRemove = errorCards.length

    if (errorCards && errorCards.length) {
      errorCards.forEach((card) => {
        card.classList.add("animate-fade-out-delay")
        card.classList.add("whitespace-nowrap")

        setTimeout(() => {
          card.remove()
        }, 1000)
      })
    }

    window.dispatchEvent(
      new CustomEvent("BulkEditErrorsPill:recalculateCount", {
        detail: { count: totalToRemove },
      }),
    )
  }

  private onRemoveHighlightColumn(event): void {
    const { origin, _container } = event.detail

    if (origin.dataset.highlightColumn) {
      const columnName = this.getColumnName(origin)

      this.getColumnCells(columnName).forEach((element) => element.classList.remove("highlighted"))
    }
  }

  handleBulkSelectUpdate(event): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      const select = event.target
      const value = this.getValue(select)
      const columnName = this.getColumnName(select)
      const selects = this.getSelects(columnName)

      selects.forEach((select) => this.setValue(select, value))
      this.addUndoActions(selects)
      this.resetPlaceholder(select)
      this.propagateEvents = true
    }
  }

  handleBulkMultiSelectUpdate(event): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      const bulkSelect = this.getBulkSelectForUpdateButton(event.target)
      const columnName = this.getColumnName(bulkSelect)

      const tsControl = bulkSelect.closest(".table-cell").querySelector(".ts-control")
      const selectedItems = Array.from(tsControl.querySelectorAll('[id^="selected-item_"]'))

      const itemsAppliesToAllValues = selectedItems
        .filter((item) => !item.textContent.includes("*"))
        .map((item) => item.id.replace("selected-item_", ""))

      const itemsAppliesToSomeValues = selectedItems
        .filter((item) => item.textContent.includes("*"))
        .map((item) => item.id.replace("selected-item_", ""))

      const selects = this.getSelects(columnName)

      selects.forEach((select) => {
        const existingValues = this.getValue(select).split(",")
        const newValues = [
          ...itemsAppliesToAllValues,
          ...itemsAppliesToSomeValues.filter((value) => existingValues.includes(value)),
        ]

        this.setValue(select, newValues)
      })
      this.addUndoActions(selects)
      this.resetPlaceholder(bulkSelect)

      if (itemsAppliesToSomeValues.length > 0) this.setMultipleValuesPlaceholder(bulkSelect)
      this.propagateEvents = true
    }
  }

  handleSelectUpdate(event): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      const select = event.target
      const columnName = this.getColumnName(select)
      const bulkSelect = this.getBulkSelect(columnName)

      this.addUndoActions([select])
      this.refreshBulkSelect(bulkSelect)
      this.propagateEvents = true
    }
  }

  handleMultiSelectUpdate(event): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      const select = this.getSelectForUpdateButton(event.target)
      const columnName = this.getColumnName(select)
      const bulkSelect = this.getBulkSelect(columnName)

      this.addUndoActions([select])
      this.refreshBulkSelect(bulkSelect)
      this.propagateEvents = true
    }
  }

  resumeSelectionForBulkEdit(event): void {
    this.refreshBulkSelect(this.getBulkSelectForPseudoInputWrapper(event.target))
  }

  confirmClose(event): void {
    const undoButton = document.getElementById("undo_button")

    if (undoButton && undoButton.disabled) {
      event.preventDefault()
      window.dispatchEvent(new CustomEvent("FullScreen:close", { detail: { reload: true } }))
    }
  }

  toggleErrorsSidebar(): void {
    window.dispatchEvent(new CustomEvent("CollapsibleSidebar:toggle"))
  }

  scrollToCell(e): void {
    let matchingFormField = document.getElementById(e.currentTarget.id.replace("_error", ""))

    if (matchingFormField) {
      const counterColumn = document.querySelector('[data-test-id="column-contract_counter"]')
      const nameColumn = document.querySelector('[data-test-id="column-description"]')
      const stickyColumnWidth = counterColumn.getBoundingClientRect().width + nameColumn.getBoundingClientRect().width
      const scrollableElement = document.getElementById("bulk-edit-scrollable-container")
      const scrollableElementRect = scrollableElement.getBoundingClientRect()

      let screenPosition = matchingFormField.getBoundingClientRect()
      const offsetTop =
        screenPosition.top -
        scrollableElementRect.top +
        scrollableElement.scrollTop -
        nameColumn.getBoundingClientRect().top
      const offsetLeft =
        screenPosition.left - scrollableElementRect.left + scrollableElement.scrollLeft - stickyColumnWidth
      scrollableElement.scrollTo({ top: offsetTop, left: offsetLeft, behavior: "smooth" })
    }
  }

  private clearBulkSelection(): void {
    window.dispatchEvent(new CustomEvent("BulkActions:clear-selection"))
  }

  private addUndoActions(selects): void {
    window.dispatchEvent(
      new CustomEvent("undo-redo:add-undo-actions", {
        detail: {
          actions: selects.map((select) => ({
            id: select.id,
            previous_value: select.dataset.value,
            current_value: this.getValue(select),
          })),
        },
      }),
    )
  }

  private refreshBulkSelect(select): void {
    if (!select) {
      return
    }

    const key = this.getColumnName(select)
    const values = this.getSelects(key).map((select) => this.getValue(select))
    const allEqual = values.length > 0 && values.every((value) => value === values[0])

    if (allEqual) {
      if (this.isMultiSelect(select)) {
        this.setValue(select, values[0].split(","))
      } else {
        this.setValue(select, values[0])
      }
      this.resetPlaceholder(select)
    } else {
      if (this.isMultiSelect(select)) {
        const uniqueValues = this.getUniqueValues(values)
        const nonCommonValues = this.getNonCommonValues(values)
        this.setValue(select, uniqueValues)
        this.addAsteriskToNonCommonValues(select, nonCommonValues)
      } else {
        this.setValue(select, "")
      }
      this.setMultipleValuesPlaceholder(select)
    }
  }

  private getValue(select): any {
    if (this.isMultiSelect(select)) {
      return Array.from(select.selectedOptions)
        .map((option) => option.value)
        .sort()
        .join(",")
    } else {
      return select.value
    }
  }

  private setValue(select, value): void {
    if (this.isMultiSelect(select)) {
      select.tomselect?.setValue(value)
      this.toggleCompactView(select)
    } else {
      select.tomselect?.setValue(value)
    }
  }

  private isMultiSelect(select): boolean {
    return select.multiple
  }

  private getBulkSelect(columnName): any {
    return this.bulkSelectTargets.filter((select) => this.getColumnName(select) === columnName)[0]
  }

  private getSelects(columnName): any[] {
    return this.selectTargets.filter((select) => this.getColumnName(select) === columnName)
  }

  private getColumnName(element): string {
    return this.getTableCell(element)?.dataset?.columnName
  }

  private getTableCell(element): any {
    return element.closest(".table-cell")
  }

  private getColumnCells(columnName): any {
    return document.getElementById("bulk-edit-table").querySelectorAll(`[data-column-name="${columnName}"]`)
  }

  private setMultipleValuesPlaceholder(select): void {
    const tomselect = select.tomselect

    if (tomselect && !this.isMultiSelect(select)) {
      tomselect.settings.placeholder = "Multiple Values"
      tomselect.wrapper.classList.add("darker-placeholder")
      tomselect.inputState()
    }

    if (this.isMultiSelect(select)) {
      const pseudoInput = select.closest(".table-cell").getElementsByClassName("pseudo-input")[0]
      pseudoInput.innerText = "Multiple Values"
    }
  }

  private resetPlaceholder(select): void {
    const tomselect = select.tomselect

    if (tomselect) {
      tomselect.settings.placeholder = "Select"
      tomselect.wrapper.classList.remove("darker-placeholder")
      tomselect.inputState()
    }
  }

  private toggleCompactView(select) {
    const tsControl = select.closest(".table-cell").getElementsByClassName("ts-control")[0]
    const selectedItems = tsControl.querySelectorAll('[id^="selected-item_"]')
    const firstSelectedName = this.getName(selectedItems[0])
    const pseudoInput = select.closest(".table-cell").getElementsByClassName("pseudo-input")[0]
    pseudoInput.innerText = firstSelectedName ? this.buildLabel(firstSelectedName, selectedItems.length) : "Not Set"
  }

  private getName(selection) {
    return selection?.childNodes[0]?.textContent.trim() || ""
  }

  private getSelectForUpdateButton(updateButton): any {
    return this.selectTargets.find((select) => updateButton.closest(".popper-dropdown").contains(select))
  }

  private getBulkSelectForUpdateButton(updateButton): any {
    return this.bulkSelectTargets.find((select) => updateButton.closest(".popper-dropdown").contains(select))
  }

  private getBulkSelectForPseudoInputWrapper(pseudoInputWrapper): any {
    return this.bulkSelectTargets.find((select) => pseudoInputWrapper.closest(".table-cell").contains(select))
  }

  private buildLabel(firstSelectionName, totalSelections) {
    return totalSelections > 1 ? `${firstSelectionName} +${totalSelections - 1}` : firstSelectionName
  }

  private getUniqueValues(arr) {
    const allValues = arr
      .flatMap((item) => item.split(",").map((value) => value.trim()))
      .filter((value) => value !== "")
    return [...new Set(allValues)]
  }

  private getNonCommonValues(arr) {
    const uniqueValues = this.getUniqueValues(arr)
    const commonValues = uniqueValues.filter((value) => arr.every((item) => item.includes(value)))
    return uniqueValues.filter((value) => !commonValues.includes(value))
  }

  private addAsteriskToNonCommonValues(select, nonCommonValues) {
    const tsControl = select.closest(".table-cell").getElementsByClassName("ts-control")[0]
    nonCommonValues.forEach((value) => {
      // if the value contains spaces or other special characters, we need to escape them
      const escapedValue = value.replace(/[ !"#$%&'()*+,./:;<=>?@[\\\]^`{|}~]/g, "\\$&")

      const selectedItem = tsControl.querySelector(`#selected-item_${escapedValue}`)
      const textNode = selectedItem.childNodes[0]
      textNode.textContent = textNode.textContent.trim() + "*"
    })
  }
}
